export const ROOT = '/home';
export const ACCESS_DENIED = '/access-denied';
export const SPLASHSCREEN = '/';
export const ADD_USER = '/user/add';
export const LIST_USER = '/listusers';
export const USER_DETAILS = '/user/view/:id';
export const EDIT_USER = '/user/edit/:id';
export const USERS = '/users/*';
export const LIST_REF_FILE_TYPES = '/reffiletypes';
export const ADD_REF_FILE_TYPE = '/reffiletypes/add';
export const EDIT_REF_FILE_TYPE = '/reffiletypes/edit/:id';
export const LIST_REF_DATA = '/reffiles';
export const REF_FILE_TYPE_DETAILS = '/reffiletypes/view/:id';
export const REF_FILE_DETAILS = '/reffiles/view/:id';
export const FORM_CUSTOMISATION = '/form-customisation';
export const UPLOAD_REF_FILE = '/reffiles/upload';
export const EDIT_REF_FILE = '/reffiles/upload/:id';
export const LIST_PAYEE = '/payees';
export const PAYEE_DETAILS = '/payees/view/:id';
export const PAYEE_BULK_DETAILS = '/payees/bulk/view/:id';
export const ADD_PAYEE = '/payees/add';
export const EDIT_PAYEE = '/payees/edit/:id';
export const LOGIN_PAGE = '/login';
export const LIST_RAW_DATA_TABLES = '/raw-data-tables';
export const LIST_RAW_DATA_BATCH = '/rawdatabatches';
export const LIST_RAW_DATA = '/rawdatabatches/view/:id';
export const RAW_DATA_CUSTOMISATION = '/raw-data-customisation/view/:mapId';
export const ADD_CHANNEL = '/channels/add';
export const EDIT_CHANNEL = '/channels/edit/:id';
export const LIST_CHANNEL = '/channels';
export const CHANNEL_DETAILS = '/channels/view/:id';
export const SUB_CHANNEL_DETAILS = '/subchannels/view/:id';
export const ADD_SUB_CHANNEL = '/subchannels/add';
export const EDIT_SUB_CHANNEL = '/subchannels/edit/:id';
export const APPROVALS = '/approvals';
export const APPROVALS_DETAILS = '/approvals/view/:id';
export const ADD_BULK_PAYEE = '/bulkpayees/add';
export const EDIT_BULK_PAYEE = '/bulkpayees/edit/:id';
export const LIST_WORKFLOW = '/commissions-console';
export const CALCULATION_DETAILS = '/calculation/view/:id';
export const LIST_PAYMENT = '/calculations';
export const LIST_TRANS_FILE_TYPES = '/transfiletypes';
export const ADD_TRANS_FILE_TYPE = '/transfiletypes/add';
export const EDIT_TRANS_FILE_TYPE = '/transfiletypes/edit/:id';
export const TRANS_FILE_TYPE_DETAILS = '/transfiletypes/view/:id';
export const LIST_TRANS_FILE = '/transfiles';
export const TRANS_FILE_DETAILS = '/transfiles/view/:id';
export const BULK_TRANS_FILE_DETAILS = '/transfiles/bulk/view/:id';
export const UPLOAD_TRANS_FILE = '/transfiles/upload';
export const EDIT_TRANS_FILE = '/transfiles/upload/:id';
export const BULK_UPLOAD_TRANS_FILE = '/transfiles/bulk-upload';
export const EDIT_BULK_UPLOAD_TRANS_FILE = '/transfiles/bulk-edit/:id';
export const BULK_UPLOAD_REF_FILE = '/reffiles/bulk-upload';
export const EDIT_BULK_UPLOAD_REF_FILE = '/reffiles/bulk-edit/:id';
export const PAYEE_TREE = 'payees/payeetree';
export const PAYMENT_REPORT = '/payment-reports';
export const BULK_REF_FILE_DETAILS = '/reffiles/bulk/view/:id';
export const ADD_CLAIM = '/claims/add';
export const EDIT_CLAIM = '/claims/edit/:id';
export const LIST_CLAIMS = '/claims';
export const CLAIM_DETAILS = '/claims/view/:id';
export const REPORTS = '/reports';
export const LIST_COMMISSION_TYPES = '/commission-types';
export const UPLOAD_SCHEME_DOCUMENT = '/schemedocuments/add';
export const LIST_SCHEME_DOCUMENT = '/schemedocuments';
export const SCHEME_DOCUMENT_DETAILS = '/schemedocuments/view/:id';
export const LIST_TARGET = '/targets';
export const PAYEE_TARGET_DETAILS = '/payeetargets/view/:id';
export const BATCH_TARGET_DETAILS = '/batchtargets/view/:id';
export const ADD_BATCH_TARGET = '/batchtargets/add';
export const EDIT_BATCH_TARGET = '/batchtargets/edit/:id';
export const LIST_PAYEE_TARGET = '/payeetargets';